import imgTV from '../../../src/images/data/sky_kids_2022.jpg';
import ios from '../../../src/images/data/app/sky-kids/skykids-ios.png';
import and from '../../../src/images/data/app/sky-kids/skykids-android.png';
import ico from '../../../src/images/data/Sky_Kids.png';

import logo from '../../../src/images-static/logo-colored.jpg';

const app = {
    data : {
        wp: {
            app: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                links :
                    {
                    prev : '/apps/sky-sports', 
                    next : '/apps/my-sky'
                },
                options : [
                    {
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>Sky Kids</h1><p>Discover the Sky Kids App today – your kids’ favourite shows, great Games and Movies. The Sky Kids App is available for Sky customers who have the Kids pack. For access to Movies you will require the Sky Cinema subscription too. Go try out the Sky Kids app for yourself in the Sky Kids area.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:imgTV
                        },
                    },
                ]
            }
        },

    }
}

export default app;